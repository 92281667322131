import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { loadStripe } from "@stripe/stripe-js";
import Cookies from "js-cookie";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import Slide from "@material-ui/core/Slide";
import Navbar from "./components/layout/navbar";
import Header from "./components/layout/Header";
import SubHeader from "./components/layout/SubHeader";
import SubHeaderDesc from "./components/layout/SubHeaderDesc";
import FileIconImage from "common/src/assets/image/saasModern/file-icon.png";
import CheckoutFormSubmit from "./components/layout/CheckoutFormSubmit";

const styles = () => ({
  Image: {
    marginLeft: 10,
    width: 80,
    height: 100
  }
});

class checkout extends Component {
  state = {
    entityName: ""
  };
  constructor() {
    super();
    this.state = { stripe: null };
  }

  componentDidMount() {
    const stripePromise = loadStripe(
      "pk_test_vNqS6n9lN2ixRsVO1uA9Hw8E00T3t83wU8"
    );

    this.getStartStore();
  }

  getStartStore() {
    this.setState({
      entityName: Cookies.get("entityName")
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Navbar />
        <div style={{ margin: 20 }}>
          <Fade in={true} timeout={1200}>
            <Slide
              direction="up"
              in={true}
              mountOnEnter
              unmountOnExit
              timeout={500}
            >
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justify="center"
              >
                <Header>Checkout</Header>
                <br />
                <img
                  src={FileIconImage}
                  alt="file-icon"
                  className={classes.Image}
                />
                <br />
                <Header>$50</Header>
                <SubHeader>{this.state.entityName}</SubHeader>
                <div style={{ maxWidth: 500, textAlign: "center" }}>
                  <SubHeaderDesc>
                    A one time fee of $50 will be charged if we are unable to
                    immediatly file an EIN for you a full refund will be applied
                    to your transaction.
                  </SubHeaderDesc>
                </div>
                <br />
                <SubHeader>Payment</SubHeader>
                <CheckoutFormSubmit />
              </Grid>
            </Slide>
          </Fade>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default withStyles(styles)(checkout);
